<template>
    <div class="homeIndex">

        <div class='top'>
            <!-- <div @click='router.push({name:"goodsDetail"})'>
                <img src='https://static.yihu365.cn/img/h5Img/assets/img/home/dazhen.png'/>
                <div>上门打针</div>
            </div> -->
            <div v-for='(item,index) in homeDetail' :key='index' @click='toGoodsDetail(item.serviceId,item.roleCode,item.scdSvCode,item.firSvCode)'>
                <img :src='item.img'/>
                <div>{{item.text}}</div>
            </div>
        </div>
        <div class='swiper_cont'>
            <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
                <van-swipe-item v-for='(item,index) in bannerList' :key='index' @click.stop='toHref(item.H5_URL)'>
                    <img :src='item.PIC_URL' />
                </van-swipe-item>
            </van-swipe>
        </div>
       <div class="bitmap">
            <div class="left_img" @click="goToListPage('011','001','002','1')">
              <p>上门血检</p>
              <img src="https://ks3-cn-beijing.ksyun.com/yihu-h5/h5-home-pic/img_shangmenxuejian.png" alt="">
            </div>
            <ul class="right_img">
                 <li class="top_gene" @click="goToListPage('002','','049','2')">
                   <p>专项筛查</p>
                   <img src="https://ks3-cn-beijing.ksyun.com/yihu-h5/h5-home-pic/img_jiyinjiance.png" alt="">
                 </li>
                 <li class="bott_jk" @click="goToListPage('011','020','002','3')">
                   <p>健康体检</p>
                   <img src="https://ks3-cn-beijing.ksyun.com/yihu-h5/h5-home-pic/img_jiankangtijian.png" alt="">
                 </li>
            </ul>
       </div>
      <div class="list_box">
        <van-tabs
            v-model:active="active"
            color="#00C291"
            duration="0.1"
            title-active-color = "#00C291"
            title-inactive-color="#999999"
            @change="onTabChange"
        >
          <van-tab
              v-for="(item,index) in serveType.target"
              :key="item.type"
              :title="item.desc"
              title-class="tit"
          >
            <div class="list">
              <div class="cont"
                   v-for="(item,index) in serveList[active]"
                   @click="toGoodsDetail('1',item.roleCode,item.scdSvCode,item.firSvCode,item.serviceType)"
              >
                <div class="img_box">
                  <img :src="item.pic" alt="">
                </div>
                <div class="serve_text">
                  <div>
                    <div class="t1">{{item.serviceName}}</div>
                    <div class="t2">{{item.descrip}}</div>
                  </div>
                  <div class="price_t4">
                    <div class="left_p">
                      <span class="price_1">￥</span>
                      <span class="price_2">{{item.singlePrice}}</span>
                      <span class="price_3">/次</span>
                    </div>
                    <div class="btn_buy" v-if="item.subscribeNum">
                      <van-icon name="friends" size="15" color="#d1d1d1"/><span>{{item.subscribeNum}}人已购买</span>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </van-tab>
        </van-tabs>
      </div>

        <template v-for='(item,index) in homeDetail.content' :key='index'>
            <div class='goods_cont' v-if='item.images.length == 3'>
                <div class='goods_title'>{{item.title}}</div>
                <div class='allway'>
                    <img :src='item.images[0].img' @click='toGoodsDetail(item.images[0].serviceId,item.images[0].roleCode,item.images[0].scdSvCode,item.images[0].firSvCode)' />
                    <div>
                        <img :src='item.images[1].img' @click='toGoodsDetail(item.images[1].serviceId,item.images[1].roleCode,item.images[1].scdSvCode,item.images[1].firSvCode)' />
                        <img :src='item.images[2].img' @click='toGoodsDetail(item.images[2].serviceId,item.images[2].roleCode,item.images[2].scdSvCode,item.images[2].firSvCode)' />
                    </div>
                </div>
            </div>

            <div class='goods_cont' v-if='item.images.length > 1'>
                <div class='goods_title'>{{item.title}}</div>
                <div class='allway1'>
                    <div class='maternal'>
                        <div class='bg1' v-for="(item1,index) in item.images">
                            <img :src='item1.img' @click='toGoodsDetail(item1.serviceId,item1.roleCode,item1.scdSvCode,item1.firSvCode,item1.serviceType)' />

                        </div>

<!--                        <div class='bg2'>-->
<!--                            <img :src='item.images[1].img' @click='toGoodsDetail(item.images[1].serviceId,item.images[1].roleCode,item.images[1].scdSvCode,item.images[1].firSvCode,item.images[1].serviceType)' />-->
<!--                        </div>-->
                    </div>
<!--                    <div class='maternal'>   -->
<!--                        <div class='bg2'>-->
<!--                            <img :src='item.images[2].img' @click='toGoodsDetail(item.images[2].serviceId,item.images[2].roleCode,item.images[2].scdSvCode,item.images[2].firSvCode,item.images[2].serviceType)' />-->
<!--                        </div>-->
<!--                        <div class='bg1'>-->
<!--                            <img :src='item.images[3].img' @click='toGoodsDetail(item.images[3].serviceId,item.images[3].roleCode,item.images[3].scdSvCode,item.images[3].firSvCode,item.images[3].serviceType)' />-->
<!--                        </div>-->
<!--&lt;!&ndash;                        <div class='bg1'>&ndash;&gt;-->
<!--&lt;!&ndash;                            <img :src='item.images[4].img' @click='toGoodsDetail(item.images[4].serviceId,item.images[4].roleCode,item.images[4].scdSvCode,item.images[4].firSvCode,item.images[4].serviceType)' />&ndash;&gt;-->
<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
                    </div>
                </div>

<!--            </div>-->
        </template>
        <div style='height:80px;background:#F2F2F2;'></div>
    </div>

</template>
<script>
    import {setWxShareDate} from '@/libs/utils.js'
import { onMounted, ref, computed,reactive } from "vue"
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { Dialog } from 'vant'
import homeApi from '@axios/home'
import { channel } from '@/config/channel.js';
    import store from "../../../store";


export default {
  name: 'homeIndex',
  components: {
  },
  setup () {
    let router = useRouter()
    const store = useStore()
    const route = useRoute()
    const active = ref(0);
    const serveType = reactive([]);
    const serveList = reactive([]);
    let thirdSid = route.query.thirdSid ? route.query.thirdSid : '';
    store.commit('setThirdSid',thirdSid)


    const Recommenduserphone = route.query.Recommenduserphone ?  route.query.Recommenduserphone : '' //护士任务参数 手机号
    const batchNumber = route.query.batchNumber ?  route.query.batchNumber : '' //护士任务参数


    let isIOS= !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)

    if(isIOS){
       window.history.pushState({}, "title", "#");
    }

    let homeDetail = ref({
        head : []
    });

    const getHome = () => {
      let params = {
         sid:store.state.sid,
         thirdSid:thirdSid
      }
        homeApi.getHome(params).then((res) => {
            homeDetail.value = res.data;
            console.log(res.data)
        })
        setWxShareDate('医护到家','https://mi.yihu365.cn/img/botlogo.jpg');// 分享
    }
    getHome();

    const bannerList =ref([])
    const getBannerList = () => {
      let formData = new FormData();
      formData.append('data',JSON.stringify({
        function : 'selectBanners',
        _from : 'h5',
        version : store.state.version,
        newversion : store.state.newversion,
        source : 'h5_users_002',
        sid : store.state.sid,
        thirdSid:thirdSid
      }))
      formData.append('encryption',false)
      homeApi.getList(formData).then((res) => {
        console.log('res1111',res.data)
        bannerList.value = res.data;

      })
    }
    getBannerList();

    const goToListPage = (roleCode,typeCode,firSvCode,type) => {
      router.push({
        name : 'bloodList',
        query : {
          rolecode : roleCode,
          typecode:typeCode,
          firsvcode : firSvCode,
          type:type,
          Recommenduserphone:Recommenduserphone,
          batchNumber:batchNumber
        }
      })
    }

    const getServicesType = () => {
      let params = {
         sid : store.state.sid,
        thirdSid:thirdSid
      }
      homeApi.getServicesType(params).then((res) => {
        serveType.target = res.data;
      })
    }
    getServicesType();

    const onTabChange = (index) => {

      console.log(active.value);

      if (!serveList[active.value]) {//没有数据才去请求
         let TypeId = (active.value + 1).toString();
        //获取服务list
        let formData = {
            type : TypeId,
            sid : store.state.sid,
          thirdSid:thirdSid
        }
        homeApi.getHotServices(formData).then((res) => {
          console.log(res);
          serveList[active.value] = res.data;
        })
      }
    }
    onTabChange();




    // 护士推广绑定用户部分
    const setBindNurse =()=> {
      const params ={
        userId:store.state.uid,
        recommenduserphone:Recommenduserphone
      }
      homeApi.setBindUserToNurse(params).then((res) => {


        if(res.code == '0000'){
          Dialog.alert({
            title:'温馨提示',
            message:'恭喜您，已跟护士绑定成功',
            theme:'round-button',
            confirmButtonColor:'rgb(0, 194, 145)'
          })
        }
        // else{
        //   Dialog.alert({
        //     title:'温馨提示',
        //     message:'很抱歉，您已绑定过护士了',
        //     theme:'round-button',
        //     confirmButtonColor:'rgb(0, 194, 145)'
        //   })
        // }

      })
    }


    if(Recommenduserphone){
      if(store.state.token){//已经登录
        setBindNurse();
      }else{
        Dialog.alert({
          title:'温馨提示',
          message:'尊敬的用户您好，为方便给您提供更好的服务，您可以跟护士做绑定关系，点击立即绑定即可。',
          theme:'round-button',
          confirmButtonText:'立即绑定',
          confirmButtonColor:'rgb(0, 194, 145)'
        }).then(()=> {

          router.push({
            name : 'login',
            query : {
              Recommenduserphone:Recommenduserphone,
              batchNumber:batchNumber

            }
          })
        })
      }

    }





    const toGoodsDetail = (id,roleCode,scdSvCode,firSvCode,serviceType) => {
        console.log(roleCode)
        if(!id){
            router.push({
                name : 'more',
                query:{
                  Recommenduserphone:Recommenduserphone,
                  batchNumber:batchNumber
                }

            })
            return
        }
        if( serviceType == 1){
            router.push({
                name : 'bloodList',
                query : {
                    id : id,
                    rolecode : roleCode,
                    scdcvcode : scdSvCode,
                    firsvcode : firSvCode,
                    Recommenduserphone:Recommenduserphone,
                    batchNumber:batchNumber

                }
            })
            return;
        }

      if(serviceType == 'jiyin'){

        router.push({
          name: 'genesDetail',
          query: {
            rolecode: roleCode,
            firsvcode: firSvCode,
            scdsvcode: scdSvCode,
            serviceType: serviceType,
            Recommenduserphone:Recommenduserphone,
            batchNumber:batchNumber
          }
        })
        return;
      }

      if(serviceType == '052' || serviceType == '2' ){
        router.push({
          name: 'newestDetail',
          query: {
            rolecode: roleCode,
            firsvcode: firSvCode,
            serviceType: serviceType,
            Recommenduserphone:Recommenduserphone,
            batchNumber:batchNumber
          }
        })
        return;
      }

      if(roleCode == '019'){
        router.push({
          name: 'serviceDetail',
          query: {
            rolecode: roleCode,
            firsvcode: firSvCode,
            scdsvcode: scdSvCode,
            Recommenduserphone:Recommenduserphone,
            batchNumber:batchNumber
          }
        })
      }
      else if(roleCode == '099'){// 健康自检 肥胖基因那一类 HPV28 碳13
        router.push({
          name: 'goodsDetail',
          query: {
            rolecode: roleCode,
            firsvcode: firSvCode,
            scdsvcode: scdSvCode,
            examCode : '001',
            Recommenduserphone:Recommenduserphone,
            batchNumber:batchNumber
          }
        })
      }
      else{
        router.push({
          name: 'goodsDetail',
          query: {
            rolecode: roleCode,
            firsvcode: firSvCode,
            scdsvcode: scdSvCode,
            Recommenduserphone:Recommenduserphone,
            batchNumber:batchNumber
          }
        })
      }
        // router.push({
        //     name : 'goodsDetail',
        //     query : {
        //         id : id,
        //         rolecode : roleCode,
        //         scdcvcode : scdSvCode,
        //         firsvcode : firSvCode
        //     }
        // })
    }

    const toHref = (url) => {
        if(!url){
            return;
        }
        if(url == 'yhIm'){
          if(store.state.token){
            window.location.href = `https://yhim.yihu365.cn?token=${store.state.token}&userId=${store.state.uid}`;
            return;
          }else{
            router.push({name:'login'});
            return;
          }

        }

        window.location.href = decodeURIComponent(url);
    }

    return {
        channel,
        store,
        active,
        toHref,
        toGoodsDetail,
        goToListPage,
        onTabChange,
        serveList,
        bannerList,
        serveType,
        homeDetail,
        router,
        setBindNurse
    }
  },

}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
    .swiper_cont{
        img{
            width: 100%;
            height:280px;
            float: left;
        }
    }
    ::v-deep(.van-swipe__indicator){
        width: 20px;
        height: 6px;
        border-radius:4px;
    }
    .top{
        padding:30px;
        padding-top:45px;
        display: flex;
        flex-wrap: wrap;
        img{
            width:88px
        }
        &>div{
            width: 25%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom:30px;
            &>div{
                font-size: 26px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #313131;
                margin-top:10px
            }
        }
    }
    .swiper_cont{
        margin:30px;
        margin-top:0;
        border-radius:20px;
        overflow: hidden;
    }
    .my-swipe .van-swipe-item {
        color: #fff;
        font-size: 20px;
        text-align: center;
        overflow: hidden;
        // display: flex;
        // background-color: #39a9ed;
    }
    .bitmap{
      display: flex;
      box-sizing: border-box;
      justify-content: space-between;
      padding:0px 30px 10px 30px;
      .left_img{
        position: relative;
        width: 48.5%;
        img{
          width: 100%;
        }
      }
      .right_img{
        width: 48.5%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .top_gene{
          position: relative;
        }
        .bott_jk{
          position: relative;
        }
        img{
          width: 100%;
        }
      }
      p{
        position: absolute;
        margin:0;
        left:40px;
        top:42px;
        color:#313131;
        font-size: 30px;
        font-weight: bold;
      }
    }




    .list_box {
      //background: #F2F2F2;
      box-sizing: border-box;
      padding: 0 30px 0 30px;
      background-image:linear-gradient(to bottom,#FEFEFE 0%,#F8F8F8 140px,#F2F2F2 50%);
      ::v-deep(.van-tabs--line .van-tabs__wrap){
        .van-tabs__nav{
          background: none;
        }
        .van-tabs__line{
          width: 50px;
        }
        .van-tabs__nav--line{
          padding-bottom: 20px;
        }
        .van-tab--active{
          font-size: 29px;
        }
      }
      .tit {
        font-size: 30px;
        color: red;
      }

      .list {
        min-height: 600px;
        font-size: 24px;
        .cont {
          background: #FFFFFF;
          display: flex;
          box-sizing: border-box;
          padding: 20px;
          border-radius:25px;
          margin-top: 20px;
          .img_box {
            width: 200px;
            height: 200px;
            margin-right: 24px;
            img {
              width: 100%;
              height: 100%;
              border-radius: 15px;
            }
          }

          .serve_text {
            box-sizing: border-box;
            width:450px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .t1 {
              margin-top: 10px;
              font-size: 30px;
              font-weight: 500;
              color: #313131;
              line-height: 38px;
            }

            .t2 {
              margin: 10px 0 10px 0;
              font-size: 25px;
              font-weight: 400;
              color: #666666;
              //height:90px;
              display: -webkit-box;
              overflow: hidden;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }

            .t3 {
              height: 60px;
              span {
                display: inline-block;
                padding: 0 10px;
                height: 40px;
                border: 1px solid #00c291;
                border-radius: 4px;
                color: #00c291;
                text-align: center;
                line-height: 40px;
                margin-right: 12px;

              }
            }

            .price_t4 {
              font-family: PingFangSC-Semibold, PingFang SC;
              display: flex;
              justify-content: space-between;
             align-items: flex-end;
              .left_p{
                margin-bottom: -3px;
              }
              .price_1 {
                color: #FF6161;
                font-size: 26px;
                font-weight: 600;
              }

              .price_2 {
                color: #FF6161;
                font-size: 38px;
                font-weight: 600;
              }

              .price_3 {
                color: #FF6161;
                font-size: 26px;
              }
              .btn_buy {
                font-size: 24px;
                color: #999999;
                margin-right: 10px;
                .van-icon{
                  vertical-align: middle;
                }
                span{
                  margin-left: 5px;
                  vertical-align: middle;
                }
              }
            }
          }

        }
      }
    }

    .goods_cont{
        padding:30px;
        padding-top:20px;

        .goods_title{
            font-size: 36px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #313131;
            padding-left:15px;
            margin-bottom: 20px;
        }
    }
    .allway{
        display: flex;
        justify-content: space-between;
        height: 250px;
        &>div{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
        }
        img{
            width: 335px;
        }
    }

    .maternal{
        width:100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        &>div{
            height: 120px;
            margin-top:20px;
            border-radius: 14px;
        }
        .m_title{
            font-size: 30px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #313131;
        }
        .m_tips{
            font-size: 24px;
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 300;
            color: #919090;
        }
    }

    .maternal{
        .bg1{
            background: #FEF5F5;
            width: 48.8%;
            img{
                width: 100%;
                /*border-radius: 14px;*/
            }
        }
        .bg2{
            background: #F8F2FF;
            width: 100%;
            img{
                width: 100%;
                border-radius: 14px;
            }
        }
    }
</style>
